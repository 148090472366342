
import request from '@/utils/request'
import qs from 'qs'

// 文献评论数
export function LiteratureComments (literature_id) {
  let data = {
   literature_id
  }
  data = qs.stringify(data)
  return request({
    url: '/home/LiteratureComments',
    method: 'post',
    data
  })
}

// 文献回复评论
export function LiteratureReplyComments(comment_id,author_id,to_id,replycontent) {
  let data = {
  comment_id,author_id,to_id,replycontent
  }
  data = qs.stringify(data)
  return request({
    url: '/home/LiteratureReplyComments',
    method: 'post',
    data
  })
}
// 文献点赞// 取消文献点赞
export function LiteratureThumbs(id,tp,aid) {
  let data = {
  id,tp,aid
  }
  data = qs.stringify(data)
  return request({
    url: '/home/LiteratureThumbs',
    method: 'post',
    data
  })
}
// 取消文献点赞
export function QXLiteratureThumbs(id,tp) {
  let data = {
  id,tp
  }
  data = qs.stringify(data)
  return request({
    url: '/home/QXLiteratureThumbs',
    method: 'post',
    data
  })
}
//查看文献回复
export function LiteratureReply(comment_id,aid) {
  let data = {
	comment_id,aid
  }
  data = qs.stringify(data)
  return request({
    url: '/home/LiteratureReply',
    method: 'post',
    data
  })
}
//查看文献评论
export function LiteratureComment(literature_id,aid) {
  let data = {
	literature_id,aid
  }
  data = qs.stringify(data)
  return request({
    url: '/home/LiteratureComment',
    method: 'post',
    data
  })
}
//文献下载更新
export function membersRight(authorId,downloadLiterature,isRechecking) {
  let data = {
    authorId,downloadLiterature,isRechecking
  }
  data = qs.stringify(data)
  return request({
    url: '/membersRight/membersRight',
    method: 'put',
    data
  })
}
//文献详情
export function LiteratureDetails(id,aid) {
  let data = {
	id,aid
  }
  data = qs.stringify(data)
  return request({
    url: '/home/LiteratureDetails',
    method: 'post',
    data
  })
}
//文献分类
export function Literature(page,limit,type_id,include_number,publishcycle,publishing,checktime,sort,name) {
  let data = {
	page,limit,type_id,include_number,publishcycle,publishing,checktime,sort,name
  }
  data = qs.stringify(data)
  return request({
    url: '/home/Literature',
    method: 'post',
    data
  })
}
// export function Literature(page,limit,type_id,include_number,publishcycle,publishing,checktime,sort) {
//   let data = {
// 	page,limit,type_id,include_number,publishcycle,publishing,checktime,yingxiangyinzi,sort
//   }
//   data = qs.stringify(data)
//   return request({
//     url: '/home/Literature',
//     method: 'post',
//     data
//   })
// }
//期刊分类
export function Classification(page,limit,type_id,include_number,publishcycle,publishlocation,checktime,yingxiangyinzi,sort) {
  let data = {
	page,limit,type_id,include_number,publishcycle,publishlocation,checktime,yingxiangyinzi,sort
  }
  data = qs.stringify(data)
  return request({
    url: '/home/Classification',
    method: 'post',
    data
  })
}
export function searchInfo (page,limit,name,sort) {
  let data = {
    page,limit,name,sort
  }
  data = qs.stringify(data);
  return request({
    url: '/Journals/searchInfo',
    method: 'post',
    data
  })
}
//学术分类
export function Academic() {
  let data = {
	
  }
  data = qs.stringify(data)
  return request({
    url: '/home/Academic',
    method: 'post',
    data
  })
}
//期刊收录
export function Include() {
  let data = {
	
  }
  data = qs.stringify(data)
  return request({
    url: '/home/Include',
    method: 'post',
    data
  })
}
//文献发布评论
export function WXPostComments(literature_id,author_id,comment) {
  let data = {
	literature_id,author_id,comment
  }
  data = qs.stringify(data)
  return request({
    url: '/home/WXPostComments',
    method: 'post',
    data
  })
}

