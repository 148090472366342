<template>
  <div>
    <div class="bg">
      <div class="w">
        <div class="mthem">
          <span class="mthemse">{{ thems }}</span>
          <div class="mthems"></div>
        </div>
        <div class="wcont">
          <div class="artleft">
            <div class="item">
              <div class="bread">
                <router-link to="/">首页</router-link> - <router-link to="/cpufreqgetpolicy">职改政策</router-link> - <span>建筑工程管理的问</span>
              </div>
              <div class="them">{{ articlelist.title }}</div>
              <p class="title">{{articlelist.name }}</p>
              <p class="updete_time">更新时间：{{ articlelist.modifytime}}</p>
              <p class="cont">{{ articlelist.content}}</p>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  width: auto;
                  margin-top: 20px;
                  height: 17px;
                  font-size: 12px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #999999;
                  line-height: 17px;
                "
              >
                <span  style="cursor: pointer;" @click="mypolicyDetails(lastarticle.id)">{{lastarticle.name}}</span>
                <span style="cursor: pointer;" @click="mypolicyDetails(lastarticle.id)">{{nextarticle.name}}</span>
              </div>
            </div>
          </div>
          <div class="artright">
            <div class="rtitle">相关文献</div>
            <p v-for="item in relation" @click="jumptodoc(item.id)">{{ item.wx_name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!--<script src="../public/layui/layui.js"></script>-->
<script scoped>
	import {policyDetails,policy,LastpolicyDetails,NextpolicyDetails} from "../../API/homepage/policy.js"
	import {Literature} from "../../API/homepage/document/document.js"
export default {
  name: 'Article_detail',
  components: {

  },
  data () {
    return {
		wpid:"",
      thems: '职改政策',
      //文章详情
      articlelist: [],

      //用户列表
      user: [],
      //评论数
      count: 122,
      //相关文献
      relation: [
      ],

	  lastarticle:[],//上一篇
	  nextarticle:[]//下一篇


    }
  },
  mounted () {
	  this.wpid=this.$route.query.wpid
	  this.mypolicyDetails()
	  this.myLiterature()
  },methods:{
	  mypolicyDetails(){
		  policyDetails(this.wpid).then(res=>{
			  console.log(res)
			  this.articlelist=res.data.data
			  return true
		  }).then(res=>{
			  NextpolicyDetails( this.articlelist.id).then(res=>{
				  this.nextarticle=res.data.data
			  })
			  LastpolicyDetails( this.articlelist.id).then(res=>{
					this.lastarticle=res.data.data		  
			  })
		  })
	  },myLiterature(){
		  Literature(1,8,'',"","","","","").then(res=>{
			  console.log(res)
			  this.relation=res.data.data
		  })
	  },jumptodoc(e){
		this.$router.push({
			path:"/article/detail",
			query:{
				'did':e
			}
		})
	  }
  }

}
</script>

<style scoped="scoped">
.bg {
  background: #f7f7f7;
  padding-top: 2px;
}
.mthem {
  text-align: center;
  height: 42px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #141414;
  line-height: 42px;
  margin-bottom: 20px;
  padding-top: 30px;
}

.magpic {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.magpic div {
  text-align: center;
  width: 18%;
  cursor: pointer;
  margin-bottom: 10px;
}
.magpic div span {
  display: block;
  margin: 8px 0;
}
.magpic div img {
  width: 180px;
}
.mthems {
  margin: auto;
  width: 68px;
  height: 3px;
  background: #ff1918;
  border-radius: 0px;
  opacity: 0.42;
}
.item {
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;
}

.bread {
  color: #999999;
  font-size: 14px;
}

.bread span {
  color: #000;
}

.them {
  font-size: 22px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 600;
  color: #141414;
  margin: 5px 0;
}

.title,
.updete_time {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  margin: 10px 0;
}
.wcont {
  display: flex;
}
.artleft {
  margin-bottom: 50px;
  flex: 3;
}
.artright {
  flex: 1;
  margin-top: 20px;
  background-color: #fff;
  margin-left: 20px;
  height: auto;
  padding: 20px;
  box-sizing: border-box;
}
.rtitle {
  height: 50px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 600;
  color: #141414;
  line-height: 50px;
  border-bottom: 1px solid #f0f0f0;
}
.artright p {
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  margin: 10px 0;
  overflow: hidden;
  cursor: pointer;
}

.cont {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
}

.xj {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}

.download {
  display: flex;
}

.download img {
  width: 12px;
}

.d {
  margin: 0 3px;
}

.comment {
  padding: 20px;
  background-color: #fff;
  margin-top: 40px;
}

.commentlist {
  padding-bottom: 10px;
  border-bottom: 1px solid #ebebeb;
}

.commentlist2 {
  padding-left: 40px;
  margin-top: 10px;
}

.ctitle {
  line-height: 50px;
}

.ctitle span {
  color: #999;
  float: right;
  font-size: 14px;
}

.user {
  color: #666666;
  font-size: 14px;
}

.user img {
  width: 34px;
  height: 34px;
  border-radius: 17px;
  vertical-align: middle;
  margin-right: 10px;
}

.sj {
  font-size: 14px;
  margin-left: 41px;
}

.sj .ico {
  width: 12px;
  height: 12px;
}

.sj i {
  margin-right: 20px;
  cursor: pointer;
}

.zh {
  padding: 20px 0;
  border-bottom: 1px solid #ebebeb;
}

.reply {
  padding: 10px;
  width: 100%;
  height: 100px;
  background: #f7f7f7;
  border-radius: 4px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  resize: none;
}
</style>